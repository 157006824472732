/* eslint-disable no-undef */
<template>
  <div>
    <bfc-bar></bfc-bar>
    <doctor-menu></doctor-menu>
    <v-divider></v-divider>
    <div style="height: 20px"></div>
    <v-app-bar color="gray" style="height: 48px">
      <v-breadcrumbs :items="breadcrumbs" style="margin-top: -16px">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div style="height: 20px"></div>
    <v-divider></v-divider>
    <v-card>
      <v-card-title>Payment Status [ {{ caseNo }} ]</v-card-title>
      <v-card-text class="text-center" v-if="loaded"
        ><h3>{{ paymentResult }}</h3>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="backToDashboard">Back to Dashboard</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
//import Payment from "../payment/payment";
import SystemBar from "../../library/system-bar/system-bar";
import { mapActions, mapGetters } from "vuex";
// eslint-disable-next-line no-unused-vars
import { nullOrUndefined } from "../../../../plugins/libs.js";
import DoctorMenu from "../menubar/menubar";
import axios from "axios";
export default {
  components: {
    //"payment-form": Payment,
    "bfc-bar": SystemBar,
    "doctor-menu": DoctorMenu,
  },
  data() {
    return {
      caseLoaded: false,
      loaded: false,
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          to: { path: "/doctor/dashboard" },
        },
        {
          text: "Payment Status",
          disabled: true,
          to: { path: "/doctor/payment-status" },
        },
      ],
      caseNo: "",
      omiseChargeToken: "",
      casePaymentInfo: {},
      doctor: {},
      custId: process.env.VUE_APP_OGF_CUST_ID,
      url: process.env.VUE_APP_LOYALTY_API_URI,
      paymentResult: "กรุณารอการตรวจสอบ",
    };
  },
  computed: {
    ...mapGetters({
      //getCaseInfo: "BFC/getCaseInfo",
      getDoctorInfo: "BFC/getDoctorInfo",
    }),
  },
  mounted() {
    // if (typeof this.getDoctorInfo.doctor != "undefined") {
    //   this.doctor = this.getInfo.doctor[0];
    // } else {
    //this.doctor = { doctor: this.$cookies.get("Profile").doctor };
    this.doctor = { doctor: JSON.parse(localStorage.getItem("Profile")).doctor };
    console.log("doctor information");
    console.log(this.doctor);
    //}
    this.breadcrumbs.push({
      text: "Case : " + this.$route.params.caseNo,
      disabled: true,
      href: "#",
    });
    this.caseNo = this.$route.params.caseNo;
    this.omiseChargeToken = this.$route.params.token;
    //async () => {
    this.getCasePaymentInfo();

    //};

    //if (this.omiseChargeToken) {

    //}
    //console.log(this.casePaymentInfo);
  },
  methods: {
    ...mapActions({
      //getCaseInfo: "BFC/getCaseInfo",
    }),

    backToDashboard() {
      this.$router.push("/doctor/dashboard");
    },
    async getCasePaymentInfo() {
      //await this.info();
      console.log(this.doctor);
      try {
        let payload = {
          caseNo: this.$route.params.caseNo,
          sequence: this.$route.params.sequence || 0,
          doctorId: this.doctor.doctor[0]._id,
          custId: this.custId,
        };
        console.log(payload);

        await axios
          .post(
            this.url + "/api/bfc/v1/payment/info",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log(" case payment info");
            console.log(response.data.data);
            if (response.data.statusCode == 0) {
              this.casePaymentInfo = response.data.data;

              this.caseLoaded = true;

              //});
              //this.addNewCase(newCase[0]);
              //this.$router.push("/doctor/dashboard");
            } else {
              // show error
              console.log("error response");
            }
          });
        console.log("------------------- get case payment info -----------------------");
        console.log(this.casePaymentInfo);
        this.paymentStatus();
      } catch (e) {
        console.log(e);
      }
    },
    async info() {
      //this.doctor = await this.getDoctorInfo;
      console.log(this.doctor);
    },
    async paymentStatus() {
      console.log("paymentStatus");
      //console.log(payment);
      //let packageValue = this.casePaymentInfo.package.value;
      //let stateStatus = this.casePaymentInfo.caseStatus.stateStatus || "Submitted";
      // let casePackage = {
      //   text: "",
      //   value: "",
      //   price: 0,
      //   packageStatus: "None",
      // };

      if (typeof packageValue !== "undefined") {
        // if (packageValue != "") {
        //   this.casePaymentInfo.package.packageStatus = "Paid";
        //   stateStatus = "Approved";
        //   casePackage = this.casePaymentInfo.package;
        // }
      }
      console.log("casePaymentInfo");
      console.log(this.casePaymentInfo);
      console.log("-----------------------------------------------------------------");
      let stateStatus =
        this.casePaymentInfo.casePayment.omiseChargeDetail.amount <= 3000
          ? "Submitted"
          : "Approved";

      let payload = {
        caseId: this.casePaymentInfo.caseInfo._id,
        doctorId: this.doctor.doctor[0]._id,
        vip: this.doctor.doctor[0].vip,
        influencer: this.doctor.doctor[0].influencer,
        caseNo: this.caseNo,
        custId: this.custId,
        charge_id: this.casePaymentInfo.casePayment.omiseChargeDetail.id,
        case: {
          custId: this.custId,
          caseId: this.casePaymentInfo.caseInfo._id,
          caseNo: this.caseNo,
          caseStatus: {
            stateStatus: stateStatus,
          },
          package: this.casePaymentInfo.caseInfo.package,
          currentStatus: this.casePaymentInfo.caseInfo.caseStatus.stateStatus,
          //package: casePackage,
        },
        casePayment: {
          _id: this.casePaymentInfo.casePayment._id,
          caseId: this.casePaymentInfo.caseInfo._id,
          memberId: this.memberId,
          custId: this.custId,
          vip: this.doctor.doctor[0].vip,
          influencer: this.doctor.doctor[0].influencer,
          caseNo: this.caseNo,
          doctorId: this.doctor.doctor[0]._id,
          payment: this.casePaymentInfo.casePayment.payment,
          paymentStatus: this.casePaymentInfo.casePayment.paymentStatus,
          paymentDate: new Date().getTime(),
        },
      };

      try {
        console.log("Await confirm payment");
        let paymentUrl = "/api/bfc/v1/payment/confirmPayment";
        console.log(" doctor vip ");
        console.log(this.doctor);
        if (this.doctor.doctor[0].vip == 1 || this.doctor.doctor[0].influencer == 1) {
          //paymentUrl = "/api/bfc/v1/payment/vipPayment";

          this.paymentResult = "Successful";
          console.log(this.paymentResult);
          this.loaded = true;
        } else {
          await axios
            .post(
              this.url + paymentUrl,
              payload, // need to change
              {
                headers: {
                  // Overwrite Axios's automatically set Content-Type
                  "Content-Type": "application/json",
                },
              }
            )
            .then(async (response) => {
              console.log(response.data);
              if (response.data.statusCode == 0) {
                if (typeof response.data.vip === "undefined") {
                  if (response.data.omiseResponse.paid) {
                    this.paymentResult = "Successful";
                  } else {
                    this.paymentResult = response.data.omiseResponse.failure_message;
                  }
                } else {
                  this.paymentResult = "Successful";
                }
                this.loaded = true;

                //alert("Saved");
                //this.$router.push("/doctor/dashboard");
              } else {
                // show error
                //console.log("error response");
                this.paymentResult = response.data.omiseResponse.failure_message;
              }
            });
        }
      } catch (e) {
        //
        console.log("error payment status");
        console.log(e);
      }
    },
  },
};
</script>
